.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
	background-color: #A7001E;
}

.logo-contact {
	display: flex;
	flex-direction: row;
}

.nous-contacter {
	background-color: #A7001E;
	color: get-color(dark,1);
}

.parent-form-contact{
	background-color: #1CB68B;
	display: flex;
	width: 100vw;

}

.form-contact {
	background-color: get-color(dark,3);
	display: flex;
	flex-direction: column;
	justify-content: center;
}


@include media( '>medium' ) {

	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}
