//H2
.h2Title {
  text-align: center;
}

.h2Title h2 {
  font-size: 2.5rem;
  margin: 0;
  line-height: 1.2;
}

@media only screen and (max-width: 767px) {
  .h2Title h2 {
    font-size: 1.8rem;
  }
}


//P1

.myZIndex{
    z-index: 10;
}

.wrapperAPropos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}


@media only screen and (min-width: 768px) { /* Styles pour les écrans de tablette */

  .h2AProposRise {
    width: 21ch;
    overflow: hidden;
    color: white;
    //font-size: 27px;
    white-space: nowrap;
    border-right: 4px solid whitesmoke;
    animation: printed-text 4s steps(26),
    flashin-border .75s step-start infinite;
  }

  @keyframes flashin-border {
    0% {
      border-color: whitesmoke;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: whitesmoke;
    }
  }

  @keyframes printed-text {
    from {
      width: 0%;
    }
  }
}

.aProposParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: get-color("primary", 1);
  height: 100vh;
  color: get-color("dark", 1);
}

.aProposTitle {
  display: flex;
  justify-content: center;
}

.textwhite {
  color: get-color("dark", 2);
}


// P2
.notreVisionParent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #0c0d0e;
}

.col-md-6 {
  width: 50%;
  background-color: #1CB68B;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.titleNotreVision {
  display: flex;
  justify-content: center;
  text-align: center;
}

.cardNotreVision {
  background-color: white;
  border-radius: 10px;
  display: flex;
}

.grille {
  max-width: 1300px;
  width: 85%;
  height: auto;
  margin: 10px auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, 330px);
  grid-gap: 20px;
  justify-content: center;
}

.grille-item {
  width: 330px;
  height: 310px;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  background-color: get-color('dark', 2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

//P3
.notreApprocheParent, {
  background-color: get-color("primary", 1);
}

.notreApproche {
  @media (min-width: 975px) {
    display: grid;
    grid-template-columns: 1.4fr 1.6fr;
    grid-template-rows: 1.5fr 1.5fr 1.5fr;
    grid-auto-columns: 1fr;
    gap: 5px 7px;
    grid-auto-flow: row;
    grid-template-areas:
    "left hRight"
    "left cRight"
    "left bRight";
  }

  @media (max-width: 767px) {
    .left {
      display: none;
    }
  }
}


.left {
  grid-area: left;
}

.bRight {
  grid-area: bRight;
}

.cRight {
  grid-area: cRight;
}

.hRight {
  grid-area: hRight;
}

.left, .bRight, .cRight, .hRight {
  border-radius: 10px;
  background-color: get-color("dark", 2);
  box-shadow: 0 5px 10px rgba(167, 0, 30, 0.4);
}

.left {
  //height: 330px;
  display: flex;
}

h5 {
  font-size: 1rem; // taille de police de base
  font-weight: bold;

  // styles pour les écrans de taille tablette et supérieure
  @media screen and (min-width: 768px) {
    font-size: 1.2rem;
  }

  // styles pour les écrans de taille bureau
  @media screen and (min-width: 1024px) {
    font-size: 1.3rem;
  }
}


//P4

.nosModesDinterventions {
  @media (min-width: 975px) {
    display: grid;
    grid-template-columns: 1.4fr 1.6fr;
    grid-template-rows: 1.5fr 1.5fr 1.5fr;
    grid-auto-columns: 1fr;
    gap: 5px 7px;
    grid-auto-flow: row;
    grid-template-areas:
    "hLeft right"
    "cLeft right"
    "bLeft right";
  }

  @media (max-width: 426px) {
    .right {
      display: none;
    }
  }
}

.right {
  grid-area: right;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.bLeft {
  grid-area: bLeft;
}

.cLeft {
  grid-area: cLeft;
}

.hLeft {
  grid-area: hLeft;
}

.right, .bLeft, .cLeft, .hLeft {
  border-radius: 10px;
  background-color: get-color("dark", 2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

//P5
.my-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: get-color('dark', 2);
  margin-right: 10px;
}

[data-title],
[data-description] {
  flex: 1;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
  color: get-color('dark', 2)
}

.description {
  font-size: 16px;
  text-align: justify;
}

@media (min-width: 768px) {
  .my-component {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .circle-row {
    flex-basis: calc(25% - 15px);
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .my-component {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}


.principauxSecteursParent {
  background-color: get-color("primary", 1);
}

.contentSecteurs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}


// ANIMATION P3 & P4
.hRight, .cRight, .bRight, .hLeft, .cLeft, .bLeft {
  opacity: 0;
}

.hRight.is-revealed, .hLeft.is-revealed {
  animation: reveal 1s ease-in-out 0s forwards;
}

.cRight.is-revealed, .cLeft.is-revealed {
  animation: reveal 1s ease-in-out 0.5s forwards;
}

.bRight.is-revealed, .bLeft.is-revealed {
  animation: reveal 1s ease-in-out 1s forwards;
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

//A PROPOS ANIMATION
.white {
  height: 100vh;
  position: absolute;
  width: 100vw;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60vh;
    background-image: linear-gradient(-180deg, #FFFFFF 0%, rgba(255,255,255,0.00) 100%) }
  &:after {
    bottom: 0;
    left: 0;
    right: 0;
    height: 20vh;
    background-image: linear-gradient(0deg, #EBEBEB 0%, rgba(235,235,235,0.00) 100%) }}

.squares {
  height: 100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden }
.square {
  animation: squares 9.5s linear infinite;
  align-self: flex-end;
  width: 1em;
  height: 1em;
  transform: translateY(100%);
  background: #105260;
  &:nth-child(2) {
    height: 1.5em;
    width: 3em;
    animation-delay: 2s;
    animation-duration: 17s;
    -webkit-filter: blur(5px) }
  &:nth-child(3) {
    height: 2em;
    width: 1em;
    animation-delay: 2.5s;
    animation-duration: 9s;
    -webkit-filter: blur() }
  &:nth-child(4) {
    height: 1em;
    width: 1.5em;
    animation-delay: 1.5s;
    -webkit-filter: blur(3px);
    animation-duration: 13s }
  &:nth-child(5) {
    height: 1.25em;
    width: 2em;
    animation-delay: 5s;
    -webkit-filter: blur(2px);
    animation-duration: 11s }
  &:nth-child(6) {
    height: 2.5em;
    width: 2em;
    animation-delay: 3s;
    -webkit-filter: blur(1px);
    animation-duration: 9s }
  &:nth-child(7) {
    height: 5em;
    width: 2em;
    -webkit-filter: blur(2.5px);
    animation-duration: 13s }
  &:nth-child(8) {
    height: 1em;
    width: 3em;
    animation-delay: 6s;
    -webkit-filter: blur(6px);
    animation-duration: 19s }
  &:nth-child(9) {
    height: 1.5em;
    width: 2em;
    -webkit-filter: blur(0.5px);
    animation-duration: 1s }
  &:nth-child(9) {
    height: 3em;
    width: 2.4em;
    animation-delay: 7s;
    -webkit-filter: blur(0.5px);
    animation-duration: 12s }}

@keyframes squares {
  from { transform: translateY(100%) rotate(-50deg) }
  to   { transform: translateY(calc(-100vh + -100%)) rotate(20deg) }}

//A PROPOS ANIMATION
