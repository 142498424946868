.text-color-primaryy {
  color: get-color(primary, 1);
}

.text-black {
  color: get-color(light, 1);
}

.recruit-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
  flex-direction: column;
}

.form-parent {
  display: flex;
  width: 100vw;
  padding: 5px;
  background-color: get-color(primary, 1);
  justify-content: center;
}

.form-child {
  background-color: #f8fafc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35vw;
  margin: 2vh;
  border-radius: 5px;
  padding-bottom: 3vh;

  @media (max-width: 767px) {
    display: flex;
    width: 90vw;
    margin: auto;
  }
}

.form-recruit {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 10px;
  border-radius: 2px;
}

.recruit-parent-paragraph {
  background-color: get-color(primary, 1);
  display: flex;
  width: 100vw;
}

.recruit-paragraph {
  margin-top: 5vh;
  color: get-color(dark, 1);
}

.button-form-recruit {
  display: flex;
  flex-direction: row;
  margin-top: 2vh;
}

.input-recruit {
  margin-top: 2vh;
}

.message-recruit {
  margin-top: 2vh;
  height: 25vh;
}

.button-recruit {
  width: 100vw;
  margin: 5px;
}

.tsparticles {
  @media (min-width: 425px) {
    display: block;

    .particule {
      display: block;
    }
  }

  @media (max-width: 425px) {
    display: none;

    .particule {
      display: none;
    }
  }
}

@media (max-width: 425px) {
  .bgImageMobile {
    //background-color: #D1200D;
    background-size: cover;
    background-position: center;
    background-image: url("../../../images/bgMobile.svg");
    background-repeat: no-repeat;
    background-color: #F4F4F4;
  }
}
