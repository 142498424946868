// Hide elements, for screen readers only
.screen-reader {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
	border: 0;
}

// Reset lists style
.list-reset {
	list-style: none;
	padding: 0;

	li {
		margin: 0;
	}
}

// Text alignment
.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

// Font weight
.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}

// Text color
.text-color-high {
	color: color(high-contrast);
}

.text-color-mid {
	color: color(mid-contrast);
}

.text-color-low {
	color: color(low-contrast);
}

.text-color-primary {
	color: color(primary);
}

.text-color-secondary {
	color: color(secondary);
}

.text-color-error {
	color: color(error);
}

.text-color-warning {
	color: color(warning);
}

.text-color-success {
	color: color(success);
}

.invert-color {

	.text-color-high {
		color: color(high-contrast-inverse);
	}

	.text-color-mid {
		color: color(mid-contrast-inverse);
	}

	.text-color-low {
		color: color(low-contrast-inverse);
	}
}

// Text transform
.tt-u {
	text-transform: uppercase;
}

// Images
.image-full {
	width: 100%;
}

.image-larger {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
	width: calc(100% + #{$image-larger--extra-width * 2});
	max-width: 100vw;
}

// Helper class for coloured bg
.has-bg-color {
	position: relative;
	//background-color: color-bg(bg-color);
	z-index: 0;
}

// Helper class for box shadow
.has-shadow {
	box-shadow: color-bg(shadow);
}

img.has-shadow {
	border-radius: $img-radius;
}

// Dividers
.has-top-divider {
    @include divider(before);
}

.has-bottom-divider {
    @include divider(after);
}

.invert-color {

	.has-top-divider {
	    @include divider(before, inverse);
	}

	.has-bottom-divider {
	    @include divider(after, inverse);
	}
}

// For centering content
.center-content {
	text-align: center;

	img,
	svg,
	video {
		margin-left: auto;
		margin-right: auto;
	}

	.button-group {
		justify-content: center;
	}
}

// Responsive video
.responsive-video {
	position: relative;
	padding-bottom: 56.25%; // 16:9
	height: 0;

	&.is-4-3 {
		padding-bottom: 75%; // 4:3
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
    }
}

// Spacing, margin
.m-0 {
	margin: 0;
}

.mt-0 {
	margin-top: 0;
}

.mr-0 {
	margin-right: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.ml-0 {
	margin-left: 0;
}

.m-4 {
	margin: 4px;
}

.mt-4 {
	margin-top: 4px;
}

.mr-4 {
	margin-right: 4px;
}

.mb-4 {
	margin-bottom: 4px;
}

.ml-4 {
	margin-left: 4px;
}

.m-8 {
	margin: 8px;
}

.mt-8 {
	margin-top: 8px;
}

.mr-8 {
	margin-right: 8px;
}

.mb-8 {
	margin-bottom: 8px;
}

.ml-8 {
	margin-left: 8px;
}

.m-12 {
	margin: 12px;
}

.mt-12 {
	margin-top: 12px;
}

.mr-12 {
	margin-right: 12px;
}

.mb-12 {
	margin-bottom: 12px;
}

.ml-12 {
	margin-left: 12px;
}

.m-16 {
	margin: 16px;
}

.mt-16 {
	margin-top: 16px;
}

.mr-16 {
	margin-right: 16px;
}

.mb-16 {
	margin-bottom: 16px;
}

.ml-16 {
	margin-left: 16px;
}

.m-24 {
	margin: 24px;
}

.mt-24 {
	margin-top: 24px;
}

.mr-24 {
	margin-right: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.ml-24 {
	margin-left: 24px;
}

.m-32 {
	margin: 32px;
}

.mt-32 {
	margin-top: 32px;
}

.mr-32 {
	margin-right: 32px;
}

.mb-32 {
	margin-bottom: 32px;
}

.ml-32 {
	margin-left: 32px;
}

// Spacing, padding
.p-0 {
	padding: 0;
}

.pt-0 {
	padding-top: 0;
}

.pr-0 {
	padding-right: 0;
}

.pb-0 {
	padding-bottom: 0;
}

.pl-0 {
	padding-left: 0;
}

.p-4 {
	padding: 4px;
}

.pt-4 {
	padding-top: 4px;
}

.pr-4 {
	padding-right: 4px;
}

.pb-4 {
	padding-bottom: 4px;
}

.pl-4 {
	padding-left: 4px;
}

.p-8 {
	padding: 8px;
}

.pt-8 {
	padding-top: 8px;
}

.pr-8 {
	padding-right: 8px;
}

.pb-8 {
	padding-bottom: 8px;
}

.pl-8 {
	padding-left: 8px;
}

.p-12 {
	padding: 12px;
}

.pt-12 {
	padding-top: 12px;
}

.pr-12 {
	padding-right: 12px;
}

.pb-12 {
	padding-bottom: 12px;
}

.pl-12 {
	padding-left: 12px;
}

.p-16 {
	padding: 16px;
}

.pt-16 {
	padding-top: 16px;
}

.pr-16 {
	padding-right: 16px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pl-16 {
	padding-left: 16px;
}

.p-24 {
	padding: 24px;
}

.pt-24 {
	padding-top: 24px;
}

.pr-24 {
	padding-right: 24px;
}

.pb-24 {
	padding-bottom: 24px;
}

.pl-24 {
	padding-left: 24px;
}

.p-32 {
	padding: 32px;
}

.pt-32 {
	padding-top: 32px;
}

.pr-32 {
	padding-right: 32px;
}

.pb-32 {
	padding-bottom: 32px;
}

.pl-32 {
	padding-left: 32px;
}

// Spacing, spacers
.spacer-4 {
	padding-top: 4px;
}

.spacer-8 {
	padding-top: 8px;
}

.spacer-12 {
	padding-top: 12px;
}

.spacer-16 {
	padding-top: 16px;
}

.spacer-24 {
	padding-top: 24px;
}

.spacer-32 {
	padding-top: 32px;
}

.spacer-48 {
	padding-top: 48px;
}

.spacer-64 {
	padding-top: 64px;
}

@include media( '<=medium' ) {

	.ta-l-mobile {
		text-align: left;
	}

	.ta-c-mobile {
		text-align: center;
	}

	.ta-r-mobile {
		text-align: right;
	}

	.center-content-mobile {
		text-align: center;

		img,
		svg,
		video {
			margin-left: auto;
			margin-right: auto;
		}

		.button-group {
			justify-content: center;
		}
	}

	// Spacing, spacers
	.spacer-4-mobile {
		padding-top: 4px;
	}

	.spacer-8-mobile {
		padding-top: 8px;
	}

	.spacer-12-mobile {
		padding-top: 12px;
	}

	.spacer-16-mobile {
		padding-top: 16px;
	}

	.spacer-24-mobile {
		padding-top: 24px;
	}

	.spacer-32-mobile {
		padding-top: 32px;
	}

	.spacer-48-mobile {
		padding-top: 48px;
	}

	.spacer-64-mobile {
		padding-top: 64px;
	}
}

@include media( '>medium' ) {

	.ta-l-desktop {
		text-align: left;
	}

	.ta-c-desktop {
		text-align: center;
	}

	.ta-r-desktop {
		text-align: right;
	}

	.center-content-desktop {
		text-align: center;

		img,
		svg,
		video {
			margin-left: auto;
			margin-right: auto;
		}

		.button-group {
			justify-content: center;
		}
	}
}
