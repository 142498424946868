@media only screen and (min-width: 767px){
  .header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #F4F4F4;
    opacity: 0;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    padding-top: 3vh;
  }
}
